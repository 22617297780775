<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-8">
        <h3>
          Kunden
          <small class="text-muted">
            <span v-if="!requesting">{{customersCount}}</span>
            <i class="fa fa-fw fa-spinner fa-pulse" v-if="requesting"></i>
          </small>
        </h3>
      </div>
      <div class="col col-xl-2 text-right">
        <input v-model="page" type="number" min="1" :max="pageCount" class="d-inline">
        /{{pageCount}}
      </div>
    </div>
    <div class="form-row">
      <div class="col-12 col-xl-3">
        <div class="form-group">
          <input
            type="text"
            v-model="query.customerId"
            class="form-control form-control-sm"
            placeholder="Kundennummer suchen..."
          >
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <div class="form-group">
          <input
            type="text"
            v-model="query.name"
            class="form-control form-control-sm"
            placeholder="Name suchen..."
          >
        </div>
      </div>
      <div class="col-12 col-xl-2">
        <div class="form-group">
          <input
            type="text"
            v-model="query.zipcode"
            class="form-control form-control-sm"
            placeholder="Postleitzahl suchen..."
          >
        </div>
      </div>
      <div class="col-12 col-xl-2">
        <div class="form-group">
          <input
            type="text"
            v-model="query.city"
            class="form-control form-control-sm"
            placeholder="Ort suchen..."
          >
        </div>
      </div>
    </div>
    <div class="row" v-if="customers.length">
      <div class="col-12 col-lg-9 col-xl-10">
        <customers-table
          :customers="customers"
          :requesting="requesting"
          @deleteCustomer="onDeleteCustomer"
          :role="role"
        ></customers-table>
      </div>
      <div class="col-12 col-lg-3 col-xl-2">
        <div class="card">
          <div class="card-body">
            <create-customer-form
              @createCustomer="onCreateCustomer"
              :requesting="requesting"
              :role="role"
            ></create-customer-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomersTable from '@/components/customers/CustomersTable';
import CreateCustomerForm from '@/components/customers/CreateCustomerForm';
import {
  createCustomer,
  getCustomers,
  getCustomersCount,
  deleteCustomer
} from '@/api';
import { auth } from '@/firebase';
import { debounce } from 'lodash';

export default {
  name: 'customers',
  components: {
    CustomersTable,
    CreateCustomerForm
  },
  data() {
    return {
      page: 1,
      customers: [],
      customersCount: 0,
      requesting: false,
      role: '',
      query: {
        limit: 50,
        offset: 0,
        name: '',
        customerId: '',
        zipcode: '',
        city: ''
      }
    };
  },
  watch: {
    page: {
      handler(page) {
        if (page > 0) {
          this.query.offset = page - 1;
        }
      }
    },
    query: {
      handler: debounce(async function(query) {
        this.requesting = true;
        this.customers = await getCustomers(query);
        this.requesting = false;
      }, 500),
      deep: true
    }
  },
  async created() {
    this.requesting = true;

    try {
      const idTokenResult = await auth.currentUser.getIdTokenResult();

      this.role = idTokenResult.claims.role;

      this.customers = await getCustomers(this.query);
      this.customersCount = await getCustomersCount();
      this.requesting = false;
    } catch (err) {
      console.error(err);
      this.requesting = false;
    }
  },
  methods: {
    async onDeleteCustomer(id) {
      if (confirm('Sind Sie sicher, dass Sie diesen Kunden löschen möchten?')) {
        try {
          this.requesting = true;

          await deleteCustomer(id);

          this.customers = this.customers.filter(
            customer => customer._id !== id
          );
          this.customersCount--;

          this.requesting = false;
        } catch (err) {
          console.error(err);

          this.requesting = false;
        }
      }
    },
    async onCreateCustomer(customer) {
      try {
        const res = await createCustomer(customer);

        this.customers.push(res);
        this.customersCount++;
      } catch (err) {
        console.error(err);
      }
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.customersCount / this.query.limit);
    }
  }
};
</script>
