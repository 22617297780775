<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <label for="customerId">Kundennummer</label>
      <input
        type="text"
        class="form-control form-control-sm"
        required
        v-model="customerId"
      />
    </div>
    <div class="form-group">
      <label for="name">Name</label>
      <input
        type="text"
        class="form-control form-control-sm"
        required
        v-model="name"
      />
    </div>
    <div class="form-group">
      <label for="zipcode">Postleitzahl</label>
      <input
        type="text"
        class="form-control form-control-sm"
        required
        v-model="zipcode"
      />
    </div>
    <div class="form-group">
      <label for="city">Ort</label>
      <input
        type="text"
        class="form-control form-control-sm"
        required
        v-model="city"
      />
    </div>
    <div class="form-group mb-0">
      <button
        type="submit"
        class="btn btn-block btn-success btn-sm"
        :disabled="requesting"
      >
        Speichern
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'create-customer-form',
  props: {
    requesting: {
      type: Boolean,
      required: true,
    },
    role: {
      type: String,
      default: 'guest',
    },
  },
  data() {
    return {
      customerId: '',
      name: '',
      zipcode: '',
      city: '',
    };
  },
  methods: {
    onSubmit() {
      this.$emit('createCustomer', {
        customerId: this.customerId,
        name: this.name,
        zipcode: this.zipcode,
        city: this.city,
      });
      this.customerId = '';
      this.name = '';
      this.zipcode = '';
      this.city = '';
    },
  },
};
</script>
